(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-components/assets/javascripts/betslip.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-components/assets/javascripts/betslip.js');
"use strict";

const {
  useEffect,
  useMemo,
  useState,
  useRef
} = React;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const {
  COUPON_VALID
} = svs.components.tipsen.engine.constants.couponStatus;
const {
  useSelector
} = ReactRedux;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  selectBetslipCouponType,
  selectCouponStatus,
  makeSelectIsCouponEmpty,
  selectIsRSystemChosen,
  selectIsUSystemChosen,
  selectIsReduceSystemChosen,
  selectCouponSignCount,
  selectMSignsCount,
  selectUSignsCount,
  selectValidCouponEvents,
  selectCouponEventCount,
  selectPrice
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  BetslipContext
} = svs.components.tipsen.betslipComponents;
const Betslip = _ref => {
  let {
    children
  } = _ref;
  const couponId = useCouponId();
  const couponStatus = useSelector(state => selectCouponStatus(state, couponId));
  const couponType = useSelector(state => selectBetslipCouponType(state, couponId));
  const isExtraSmall = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const isMedium = useMediaQuery(breakpoints.up(BREAKPOINT_MD));
  const [isBetslipVisible, setIsBetslipVisible] = useState(!isExtraSmall || couponStatus === COUPON_VALID);
  const selectIsCouponEmpty = useRef(makeSelectIsCouponEmpty());
  const couponSignCount = useSelector(state => selectCouponSignCount(state, couponId));
  const uSignCount = useSelector(state => selectUSignsCount(state, couponId));
  const mSignCount = useSelector(state => selectMSignsCount(state, couponId));
  const isCouponEmpty = useSelector(state => selectIsCouponEmpty.current(state, couponId));
  const isRsystemSet = useSelector(state => selectIsRSystemChosen(state, couponId));
  const isUsystemSet = useSelector(state => selectIsUSystemChosen(state, couponId));
  const isReduceSystem = useSelector(state => selectIsReduceSystemChosen(state, couponId));
  const couponEventsStarted = useSelector(state => selectValidCouponEvents(state, couponId, 0));
  const couponEventCount = useSelector(state => selectCouponEventCount(state, couponId, 0));
  const isCouponValid = useMemo(() => couponStatus === COUPON_VALID, [couponStatus]);
  const price = useSelector(state => selectPrice(state, couponId, true));
  const [isCouponValidWithDelay, setIsCouponValidWithDelay] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isCouponValid) {
      timeoutId = setTimeout(() => {
        setIsCouponValidWithDelay(true);
      }, 500);
    } else {
      setIsCouponValidWithDelay(false);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isCouponValid]);
  const context = useMemo(() => ({
    couponBetButtonCount: couponSignCount + uSignCount + mSignCount,
    couponEventCount,
    couponEventsStarted,
    couponId,
    couponType,
    isAnySystemSelected: isRsystemSet || isUsystemSet || isReduceSystem,
    isBetslipVisible,
    isCouponEmpty,
    isCouponValid,
    isCouponValidWithDelay,
    isExtraSmall,
    isMedium,
    isReduceSystem,
    isRsystemSet,
    isUsystemSet,
    price,
    setIsBetslipVisible,
    couponStatus
  }), [couponEventCount, couponEventsStarted, couponId, couponSignCount, couponType, isBetslipVisible, isCouponEmpty, isCouponValid, isCouponValidWithDelay, isExtraSmall, isMedium, isReduceSystem, isRsystemSet, isUsystemSet, mSignCount, price, uSignCount, couponStatus]);
  return React.createElement(BetslipContext.Provider, {
    value: context
  }, children);
};
setGlobal('svs.components.tipsen.betslipComponents.Betslip', Betslip);

 })(window);